<template>
    <div class="col-md-7">

    <h4 class="classic-title"><span>Varauslomake</span></h4>
    <p v-if="varaukset.varaus_auki">Täytä lomake huolellisesti väärinkäsitysten välttämiseksi.</p>
    <p v-if="!varaukset.varaus_auki">{{ varaukset.varauslomake_teksti }}</p>
    <div class="contact-form" v-if="varaukset.varaus_auki">
        <form action="#" class="form-horizontal" id="reservation-form" name="reservation-form" method="post" @submit.prevent="sendTicket()">
        <div class="form-group">
            <h5>Vuokraajan tiedot</h5>
        </div>
        <div class="form-group">
            <label for="name" class="col-md-4">Nimi<span class="required">*</span></label>
            <div class="col-md-8">
            <input v-model="varaus.nimi" @change="validate()" type="text" value="" size="50" class="form-control">
            </div>
        </div>
        <div class="form-group">
            <label for="e-mail" class="col-md-4">Sähköposti<span class="required">*</span></label>
            <div class="col-md-8">
            <input v-model="varaus.sahkoposti" @change="validate()" type="text" value="" size="50" class="form-control">
            </div>
        </div>
        <div class="form-group">
            <label for="phone" class="col-md-4">Puhelin<span class="required">*</span></label>
            <div class="col-md-8">
            <input v-model="varaus.puhelin" @change="validate()" type="text" value="" size="20" class="form-control">
            </div>
        </div>

        <div class="form-group">
            <label for="address" class="col-md-4">Postiosoite<span class="required">*</span></label>
            <div class="col-md-8">
            <input v-model="varaus.postiosoite" @change="validate()" type="text" value="" size="50" class="form-control">
            </div>
        </div>
        <div class="form-group">
            <label for="postal-code" class="col-md-4">Postinumero<span class="required">*</span></label>
            <div class="col-md-8">
            <input v-model="varaus.postinumero" @change="validate()" type="text" value="" size="10" class="form-control">
            </div>
        </div>
        <div class="form-group">
            <label for="city" class="col-md-4">Postitoimipaikka<span class="required">*</span></label>
            <div class="col-md-8">
            <input v-model="varaus.postitoimipaikka" @change="validate()" type="text" value="" size="50" class="form-control">
            </div>
        </div>

        <div class="form-group">
            <h5>Vuokrauksen tiedot</h5>
        </div>
        <div class="form-group">
            <label for="dates" class="col-md-4">Vuokra-aika<span class="required">*</span></label>
            <div class="col-md-8">
            <select v-model="varaus.vuokra_aika" @change="validate()" class="form-control">
                <option value="">Valitse viikko</option>
                <option v-for="vko in varaukset.varaustilanne" :key="vko.viikko" :value="vko.viikko + ': ' + vko.pvm" :disabled="vko.varattu">{{ vko.viikko }} ({{ vko.pvm }}) {{ vko.varattu ? ' - Varattu' : '' }}</option>
            </select>
            </div>
        </div>
        <div class="form-group">
            <label for="mileage" class="col-md-4">Arvioitu ajomatka<span class="required">*</span></label>
            <div class="col-md-8">
            <select v-model="varaus.ajomatka" @change="validate()" class="form-control">
                <option value="">Valitse arvioitu ajomatka</option>
                <option v-for="matka in ajomatkat" :key="matka.arvo" :value="matka.arvo">{{ matka.kuvaus }}</option>
            </select>
            </div>
        </div>
        <div class="form-group">
            <label class="col-md-4">Vuokraehdot<span class="required">*</span></label>
            <div class="col-md-8">
            <input v-model="varaus.ehdot" @change="validate()" id="terms" name="terms" type="checkbox" value="Kyllä" class="col-md-1">
            <label for="terms" class="col-md-11">Olen tutustunut huolellisesti ja hyväksyn <a
                style="text-decoration: underline;" href="/vuokrausehdot" target="_blank">vuokrausehdot</a>.</label>
            </div>
        </div>
        <div class="form-group">
            <h5>Alennukset</h5>
        </div>
        <div class="form-group">
            <label class="col-md-4">Maksutapa-alennus, 5%</label>
            <div class="col-md-8">
            <input v-model="varaus.maksutapa_ale" id="payment-discount" name="payment-discount" type="checkbox" value="Kyllä" class="col-md-1">
            <label for="payment-discount" class="col-md-11">Maksan vuokran 2 kuukautta ennen vuokra-ajan alkua ja
                olen oikeutettu <a style="text-decoration: underline;" href="/hinnasto" target="_blank">hinnaston</a>
                mukaiseen 5% alennukseen.</label>
            </div>
        </div>
        <div class="form-group">
            <h5>Lisäpalvelut</h5>
        </div>
        <div class="form-group">
            <label for="linen" class="col-md-4">Liinavaatteet, 15,00 €/hlö</label>
            <div class="col-md-8">
            <select v-model="varaus.liinavaatteet" name="linen" id="linen" class="form-control">
                <option v-for="liinat in liinavaatteet" :key="liinat.arvo" :value="liinat.arvo">{{ liinat.kuvaus }}</option>
            </select>
            </div>
        </div>
        <div class="form-group">
            <label class="col-md-4">Loppuhuolto, 200,00 €</label>
            <div class="col-md-8">
            <input v-model="varaus.loppuhuolto" id="service" name="service" type="checkbox" value="Kyllä" class="col-md-1">
            <label for="service" class="col-md-11">Huollon jälkeen auto on vuokrasopimuksen mukaisessa
                luovutuskunnossa, polttoainetankkausta lukuunottamatta.</label>
            </div>
        </div>
        <div class="form-group">
            <label class="col-md-4">Omavastuun puolitus, 200,00 €</label>
            <div class="col-md-8">
            <input v-model="varaus.omavastuun_puolitus" id="deductible" name="deductible" type="checkbox" value="Kyllä" class="col-md-1">
            <label for="deductible" class="col-md-11">Matkailuauton liikenne- ja täyskaskovakuutuksen omavastuun
                (1200€) puolitus (600€).</label>
            </div>
        </div>
        <div class="form-group">
            <h5>Lisätiedot</h5>
        </div>
        <div class="form-group">
            <label for="message" class="col-md-4">Viesti</label>
            <div class="col-md-8">
            <textarea v-model="varaus.viesti" id="message" name="message" cols="45" rows="5" class="form-control"></textarea>
            </div>
        </div>
        <div class="form-group">
            <div id="form-error" class="alert alert-danger fade in" v-if="errors.length > 0">
            <strong>Täytä puuttuvat tai virheelliset tiedot</strong>
            <ul>
                <li v-for="err in errors" :key="err">{{ err }}</li>
            </ul>
            </div>
            <input type="submit" name="submit" class="button" id="submit-btn" value="Lähetä varaus" :disabled="lahetetaan || lahetys == 200">
        </div>
        <div class="form-group" v-if="lahetetaan">
            <div class="col-md-8">Varausta lähetetään...</div>
        </div>
        </form>
    </div>
    <div id="form-send-success" class="alert alert-success fade in" v-if="lahetys > 0 && lahetys == 200">
        <strong>Kiitos varauksesta!</strong><br>Vahvistamme varauksesi mahdollisimman pian.
    </div>
    <div id="form-send-error" class="alert alert-danger fade in" v-if="lahetys > 0 && lahetys != 200">
        <strong>Oops!</strong><br>Varauksen lähettämisessä tapahtui virhe.<br><span
        id="freshdesk-err"></span><br>Yritä myöhemmin uudelleen.
    </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
      return {
          varaukset: {},
          varaus: {
              nimi: '',
              sahkoposti: '',
              puhelin: '',
              postiosoite: '',
              postinumero: '',
              postitoimipaikka: '',
              vuokra_aika: '',
              ajomatka: '',
              ehdot: false,
              maksutapa_ale: false,
              liinavaatteet: 0,
              loppuhuolto: false,
              omavastuun_puolitus: false,
              viesti: '',
          },
          ajomatkat: [
              { arvo: 'Ei pakettia, yhteensä 1000 km', kuvaus: '1000 km (sis. perushintaan)' },
              { arvo: '500 km, yhteensä 1500 km', kuvaus: '1500 km (+120,00€)' },
              { arvo: '1000 km, yhteensä 2000 km', kuvaus: '2000 km (+220,00€)' },
              { arvo: '1500 km, yhteensä 2500 km', kuvaus: '2500 km (+300,00€)' },
              { arvo: '2000 km, yhteensä 3000 km', kuvaus: '3000 km (+375,00€)' },
          ],
          liinavaatteet: [
              { arvo: 0, kuvaus: 'Ei liinavaatteita' },
              { arvo: 1, kuvaus: '1 henkilölle' },
              { arvo: 2, kuvaus: '2 henkilölle' },
              { arvo: 3, kuvaus: '3 henkilölle' },
              { arvo: 4, kuvaus: '4 henkilölle' },
              { arvo: 5, kuvaus: '5 henkilölle' },
              { arvo: 6, kuvaus: '6 henkilölle' },
          ],
          validoidaan: false,
          errors: [],
          lahetetaan: false,
          lahetys: 0,
      }
  },
  methods: {
      getVarattu(viikko) {
          return viikko.varattu ? 'Varattu' : 'Vapaa';
      },
      validate() {
          if (this.validoidaan) {
            this.errors = [];
            const filter = /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
            if (!this.varaus.nimi) this.errors.push('Nimi on pakollinen');
            if (!this.varaus.sahkoposti || !filter.test(this.varaus.sahkoposti) ) this.errors.push('Sähköposti on pakollinen ja sen on oltava toimiva');
            if (!this.varaus.puhelin) this.errors.push('Puhelin on pakollinen');
            if (!this.varaus.postiosoite) this.errors.push('Postiosoite on pakollinen');
            if (!this.varaus.postinumero) this.errors.push('Postinumero on pakollinen');
            if (!this.varaus.postitoimipaikka) this.errors.push('Postitoimipaikka on pakollinen');
            if (!this.varaus.vuokra_aika) this.errors.push('Vuokra-aika on pakollinen');
            if (!this.varaus.ajomatka) this.errors.push('Arvioitu ajomatka on pakollinen');
            if (!this.varaus.ehdot) this.errors.push('Vuokraehdot on hyväksyttävät');
          }
          return this.errors.length < 1;
      },
      async sendTicket() {
          this.validoidaan = true;
          if (this.validate()) {
              this.lahetetaan = true;
              const reservation = `Varauksen tiedot:
Nimi: ${this.varaus.nimi}
Sähköposti: ${this.varaus.sahkoposti}
Puhelin: ${this.varaus.puhelin}
Postiosoite: ${this.varaus.postiosoite}
Postinumero: ${this.varaus.postinumero}
Postitoimipaikka: ${this.varaus.postitoimipaikka}

Vuokra-aika: ${this.varaus.vuokra_aika}
Lisäkilometripaketti: ${this.varaus.ajomatka}
Ehdot hyväksytty: ${this.varaus.ehdot ? 'Kyllä' : 'Ei'}

Maksutapa-alennus: ${this.varaus.maksutapa_ale ? 'Kyllä' : 'Ei'}
Liinavaatteet: ${this.varaus.liinavaatteet} henkilölle
Loppuhuolto: ${this.varaus.loppuhuolto ? 'Kyllä' : 'Ei'}
Omavastuun puolitus: ${this.varaus.omavastuun_puolitus ? 'Kyllä' : 'Ei'}

Viesti:
${this.varaus.viesti}`;
            const response = await axios.post('https://vuokraamatkailuauto.freshdesk.com/helpdesk/tickets.json', 
            {
                helpdesk_ticket : {
                    group_id: 14000076078,
                    name : this.varaus.nimi,
                    email : this.varaus.sahkoposti,
                    subject : 'Uusi varaus, ' + this.varaus.nimi, 
                    description : reservation, 
                    priority : 1,
                    status : 2,
                }, 
            }, 
            {
                headers: {
                    'Authorization': 'Basic ' + btoa('x2GNM0ffzZnFKMFobZsK:X'),
                    'Content-Type': 'application/json',
                },
            });

            this.lahetys = response.status;
            this.lahetetaan = false;
          } else {
              console.log('Error');
          }
      }
  },
  async mounted () {
    const response = await axios.get(`/varaustilanne.json?_=${Date.now()}`);
    this.varaukset = response.data;
  }
}

</script>

<style>

</style>


