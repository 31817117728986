import Vue from 'vue'
import Varaustilanne from './Varaustilanne.vue'
import Varauslomake from './Varauslomake.vue'

Vue.config.productionTip = false

new Vue({
  render: h => h(Varaustilanne),
}).$mount('#varaustilanne')

new Vue({
  render: h => h(Varauslomake),
}).$mount('#varauslomake')