<template>
  <div class="col-md-5">
        <h4 class="classic-title"><span>Varaustilanne</span></h4>
        <p v-if="!varaukset.varaus_auki">{{ varaukset.varaustilanne_teksti }}</p>
        <table v-if="varaukset.varaus_auki" class="col-md-10 reservations">
          <tbody>
            <tr>
              <th style="width: 50%">Viikko</th>
              <th>Perushinta</th>
              <th>Tilanne</th>
            </tr>
            <tr v-for="vko in varaukset.varaustilanne" :key="vko.viikko" :class="vko.varattu ? 'reserved' : ''">
                <td>{{ vko.viikko }} {{ vko.pvm }}</td>
                <td>{{ parseInt(vko.hinta, 10)}} €</td>
                <td>{{ getVarattu(vko) }}</td>
            </tr>
          </tbody>
        </table>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
      return {
          varaukset: {},
      }
  },
  methods: {
      getVarattu(viikko) {
          return viikko.varattu ? 'Varattu' : 'Vapaa';
      }
  },
  async mounted () {
    const response = await axios.get(`/varaustilanne.json?_=${Date.now()}`);
    this.varaukset = response.data;
  }
}
</script>

<style>

</style>
